import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import ReactShowMoreText from 'react-show-more-text';
import {
  FdTextField,
  FdMarkdownEditor,
  FdTypography,
  FdIconsV5,
  FdMarkdownRender,
  FdDelayed,
  FdTooltip,
  FdSelect,
  DIFFICULTY_OPTIONS,
} from '@fifthdomain/fe-shared';
import EditContainer from './EditContainer';
import FdTextView from '../../FdTextView';
import Attachments from '../Attachments';
import Lab from '../Lab';
import Solutions from '../Solution';
import FileAttachment from '../../FileAttachment/FileAttachment';

const DetailsAndResources = ({ isReviewer, onSubmit }) => {
  const { InsertDriveFileOutlined, InfoOutlined } = FdIconsV5;
  const {
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useFormContext();
  const {
    name,
    description,
    lab,
    summary,
    difficulty,
    estimatedSolveTime,
    flag,
    solnWalkthrough,
  } = getValues();

  return (
    <Box className="flex flex-col gap-y-3">
      <EditContainer
        title="Details"
        labelOnly={
          <Box>
            <FdTextView mt={2} label="Name" value={name} />
            <FdTextView
              mt={2}
              label="Description"
              value={
                <ReactShowMoreText
                  more="See more"
                  less="See less"
                  anchorClass="see-more"
                >
                  {description}
                </ReactShowMoreText>
              }
            />
            <FdTextView mt={2} label="Summary" value={summary} />
            <FdTextView
              mt={2}
              label="Proficiency Level"
              value={difficulty}
              tooltip="Proficiency Level"
            />
            <FdTextView
              mt={2}
              label="Estimated Time to solve"
              value={`${estimatedSolveTime} min`}
            />
          </Box>
        }
        hideEdit={isReviewer}
        triggerFields={[
          'name',
          'description',
          'summary',
          'difficulty',
          'estimatedSolveTime',
        ]}
        errors={errors}
        onCancel={() => reset()}
        trigger={trigger}
        onSave={onSubmit}
      >
        <Box display="flex" flexDirection="column">
          <Controller
            control={control}
            name="name"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mt={1} mb={2}>
                <FdTextField
                  id="name"
                  label="Challenge Name"
                  required
                  fullWidth
                  error={error}
                  helperText={error ? error.message : null}
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
          <Box mt={0}>
            <Controller
              control={control}
              name="description"
              render={({ field, fieldState: { error } }) => (
                <Box mb={2}>
                  <FdMarkdownEditor
                    id="description"
                    name="description"
                    markdown={field.value}
                    setMarkdown={field.onChange}
                    label="Description"
                    required
                    error={error}
                    errorText={error && error.message}
                    {...field}
                  />
                </Box>
              )}
            />
          </Box>
          <Box mt={1}>
            <Controller
              control={control}
              name="summary"
              render={({ field, fieldState: { error } }) => (
                <Box mb={2}>
                  <FdTypography variant="subtitle1">Summary</FdTypography>
                  <Box mb={1}>
                    <FdTypography variant="captiontext1" color="secondary">
                      Summary is viewable only by admins/managers for quick
                      reference and overview of the challenge for use-cases such
                      as challenge curation for events, leasing challenges etc.
                    </FdTypography>
                  </Box>
                  <FdMarkdownEditor
                    id="summary"
                    markdown={field.value}
                    setMarkdown={field.onChange}
                    required
                    error={error}
                    errorText={error && error.message}
                    {...field}
                  />
                </Box>
              )}
            />
          </Box>
          <FdDelayed delay={2000}>
            <Box mt={0} mb={2} width={276}>
              <Controller
                control={control}
                name="difficulty"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mt={0}>
                    <FdSelect
                      id="difficulty"
                      inputTitle={
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ marginBottom: '-10px' }}
                        >
                          <FdTypography variant="subtitle1">
                            Proficiency Level
                          </FdTypography>
                          <FdTooltip title="The proficiency level for a cyber challenge specifies the required expertise, ranging from Novice (1-2), Advanced Beginner (3-4), Competent (5-6), Proficient (7-8) to Expert (9-10).">
                            <IconButton>
                              <InfoOutlined />
                            </IconButton>
                          </FdTooltip>
                        </Box>
                      }
                      options={DIFFICULTY_OPTIONS}
                      defaultSelected={rest.value}
                      error={error}
                      helperText={error && error.message}
                      {...rest}
                      inputRef={ref}
                    />
                  </Box>
                )}
              />
            </Box>
          </FdDelayed>
          <Box style={{ width: '276px' }}>
            <Controller
              control={control}
              name="estimatedSolveTime"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box>
                  <FdTextField
                    id="estimatedSolveTime"
                    label="Estimated Time to Solve"
                    endAdornment="Minutes"
                    required
                    fullWidth
                    error={error}
                    helperText={error ? error.message : null}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
          </Box>
        </Box>
      </EditContainer>
      <EditContainer
        title="Flag"
        labelOnly={
          <Box>
            <FdTextView mt={2} label="Flag" value={flag} noHeading />
          </Box>
        }
        hideEdit={isReviewer}
        errors={errors}
        triggerFields={['flag']}
        trigger={trigger}
        onCancel={() => reset()}
        onSave={onSubmit}
      >
        <Controller
          control={control}
          name="flag"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <FdTextField
              id="flag"
              label="Flag"
              required
              error={error}
              helperText={error ? error.message : null}
              placeholder="FLAG{text}"
              {...rest}
              inputRef={ref}
            />
          )}
        />
      </EditContainer>
      <EditContainer
        title="Lab"
        labelOnly={
          <Box>
            <FdTextView
              mt={2}
              label="Lab"
              value={lab || 'No Lab Attached'}
              noHeading
            />
          </Box>
        }
        hideEdit={isReviewer}
        errors={errors}
        triggerFields={['lab']}
        trigger={trigger}
        onCancel={() => reset()}
        onSave={onSubmit}
      >
        <Lab isEdit={!isReviewer} isReviewer={isReviewer} fromReviewPage />
      </EditContainer>
      <EditContainer
        title="Attachments"
        labelOnly={
          <Box>
            {getValues('uploadAttachments')?.map((f) => (
              <Box className="flex items-center gap-x-2 my-1">
                <InsertDriveFileOutlined />
                <FileAttachment
                  id={f.id}
                  key={f.id}
                  name={f.name}
                  url={f.url}
                />
              </Box>
            ))}
          </Box>
        }
        hideEdit={isReviewer}
        errors={errors}
        triggerFields={['uploadAttachments']}
        trigger={trigger}
        onCancel={() => reset()}
        onSave={onSubmit}
      >
        <Attachments />
      </EditContainer>
      <EditContainer
        title="Solution"
        labelOnly={
          <Box my={2}>
            <FdMarkdownRender markdown={solnWalkthrough} />
          </Box>
        }
        hideEdit={isReviewer}
        errors={errors}
        triggerFields={['solnWalkthrough']}
        trigger={trigger}
        onCancel={() => reset()}
        onSave={onSubmit}
      >
        <Solutions />
      </EditContainer>
    </Box>
  );
};

DetailsAndResources.propTypes = {
  isReviewer: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DetailsAndResources;
